"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ac_params_factory", {
  enumerable: true,
  get: function () {
    return _common.ac_params_factory;
  }
});
Object.defineProperty(exports, "checkReturnSubmitRules", {
  enumerable: true,
  get: function () {
    return _common2.checkReturnSubmitRules;
  }
});
Object.defineProperty(exports, "clean_file", {
  enumerable: true,
  get: function () {
    return _common.clean_file;
  }
});
Object.defineProperty(exports, "download_factory", {
  enumerable: true,
  get: function () {
    return _common.download_factory;
  }
});
exports.feedbackMenu = feedbackMenu;
Object.defineProperty(exports, "fileIsViewable", {
  enumerable: true,
  get: function () {
    return _common.fileIsViewable;
  }
});
exports.getDefaultRowData = getDefaultRowData;
Object.defineProperty(exports, "getEpoch", {
  enumerable: true,
  get: function () {
    return _common.getEpoch;
  }
});
Object.defineProperty(exports, "getFeedbackLabel", {
  enumerable: true,
  get: function () {
    return _common2.getFeedbackLabel;
  }
});
exports.get_fmi_by_key = get_fmi_by_key;
exports.get_fmi_key_by_values = get_fmi_key_by_values;
exports.hasWarningPdf = void 0;
Object.defineProperty(exports, "isRoObject", {
  enumerable: true,
  get: function () {
    return _common.isRoObject;
  }
});
exports.is_add_doc_btn_top = is_add_doc_btn_top;
Object.defineProperty(exports, "not_implemented", {
  enumerable: true,
  get: function () {
    return _common.not_implemented;
  }
});
exports.onClickPreview = onClickPreview;
Object.defineProperty(exports, "outsideSubmittedDocuments", {
  enumerable: true,
  get: function () {
    return _common2.outsideSubmittedDocuments;
  }
});
exports.pkg_find_row_by_row_id = void 0;
Object.defineProperty(exports, "pr", {
  enumerable: true,
  get: function () {
    return _common.pr;
  }
});
exports.row_fields = void 0;
exports.shouldHighlight = shouldHighlight;
exports.shouldHighlightField = shouldHighlightField;
exports.successUnsubmitText = void 0;
Object.defineProperty(exports, "unassignedOrphanFlags", {
  enumerable: true,
  get: function () {
    return _common2.unassignedOrphanFlags;
  }
});
Object.defineProperty(exports, "unassignedOrphanNote", {
  enumerable: true,
  get: function () {
    return _common2.unassignedOrphanNote;
  }
});
Object.defineProperty(exports, "user_not_reged", {
  enumerable: true,
  get: function () {
    return _common.user_not_reged;
  }
});
Object.defineProperty(exports, "walkThrough", {
  enumerable: true,
  get: function () {
    return _common.walkThrough;
  }
});
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.regexp.flags.js");
var _common = require("../../ui-components/common");
var _common2 = require("../../business/common");
const pkg_find_row_by_row_id = function (pkg, row_id) {
  var row_path = -1;
  $.each(pkg.data.package_data.sections, function (j, section) {
    $.each(section.rows, function (i, row) {
      if (row && row.id && row.id == row_id) {
        row_path = "package_data/sections#" + section.id + "/rows#" + row.id;
        return false;
      }
    });
  });
  return row_path;
};

// https://builds.atlassian.net/browse/SUB-477 package highlighting
exports.pkg_find_row_by_row_id = pkg_find_row_by_row_id;
function shouldHighlight(tm) {
  let flags = this.props.flags;
  if (!flags.highlightOn) return false;
  if (!tm || !flags.flowLastAck) return false;
  return tm > flags.flowLastAck;
}

// https://builds.atlassian.net/browse/SUB-477 package highlighting
function shouldHighlightField(obj, sfield, selsewhere_field) {
  let flags = this.props.flags,
    field = sfield,
    elsewhere_field = selsewhere_field;
  if (!flags.highlightOn) return false;
  // all new SUB-477 3.1 -- No more highlighted
  // https://builds.atlassian.net/browse/SUB-477?focusedCommentId=111707&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-111707
  if (!flags.flowLastAck) return false;
  if (!obj[field]) {
    if (!elsewhere_field) return false;
    field = selsewhere_field;
    elsewhere_field = undefined;
    if (!obj[field]) return false;
  }
  // local modification time
  var mtime = obj[field + '_mtime'];
  // modification time from elsewhere
  var __mtime = obj['__' + field + '_mtime'];

  // if a field is locally-modified, do not highlight it
  if (mtime && __mtime && mtime > __mtime) return false;
  // feedback use flow_from_above_feedback for example
  if (elsewhere_field && mtime) {
    let __mtime = obj[`__${elsewhere_field}_mtime`];
    if (__mtime && mtime > __mtime) return false;
  }
  return __mtime > flags.flowLastAck;
}
function getDefaultRowData() {
  return {
    subsection: '',
    product_specified: '',
    manufacturer: '',
    product_submitted: '',
    items: [],
    tags: {},
    notes: ''
  };
}
function is_add_doc_btn_top(preg) {
  if (preg && preg.mode && preg.mode == "review") return true;
  return false;
}
function feedbackMenu(pkg, row) {
  let key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'base';
  if (!pkg || !row) return [];
  const fbOptions = (0, _common2.feedbackOptions)(key);
  const flow_level = pkg.registry.flow_level;
  let feedBackMenuItems = _.reduce(fbOptions, (arr, rowFeedback) => {
    let showRowFeedback = true;
    if (rowFeedback.only_on_created_below_rows) {
      if (typeof row.flow_origin_level === 'undefined' || row.flow_origin_level >= flow_level) {
        showRowFeedback = false;
      }
    }
    if (flow_level > 0 && !rowFeedback.can_use_level_above_0 || flow_level === 0 && !rowFeedback.can_use_level_0 || flow_level < 0 && !rowFeedback.can_use_level_below_0) {
      showRowFeedback = false;
    }
    if (!showRowFeedback) return arr;
    rowFeedback.ui.forEach(uiItem => {
      arr.push({
        value: rowFeedback.code,
        item_name: uiItem.item_name,
        with_notes: uiItem.with_notes
      });
    });
    return arr;
  }, []);
  return feedBackMenuItems;
}
function get_fmi_key_by_values(fb_menu, fb_value, fb_notes, schema) {
  if (!fb_menu || !fb_value) return undefined;
  if (_.isUndefined(fb_notes)) fb_notes = null;
  let fmi_original = undefined;
  for (let fmi_i in fb_menu) {
    let fmi = fb_menu[fmi_i];
    if (fmi.value == fb_value) {
      if (fmi.with_notes ? fb_notes != null : fb_notes === null) {
        if (fmi_original) {
          console.error("get_fmi_key_by_values: more than 1", fb_menu, fb_value, fb_notes, fmi_original, fmi);
          return;
        }
        fmi_original = fmi;
      }
    }
  }
  if (!fmi_original) return undefined;
  //base schema has equal codes with/without notes so we differ them by "_with_notes" appendix
  return fmi_original.value + (schema === 'base' && fmi_original.with_notes ? "_with_notes" : "");
}
function get_fmi_by_key(fb_menu, fb_key, schema) {
  if (!fb_menu || !fb_key) return undefined;
  let fb_menu_map = {};
  for (let fmi_i in fb_menu) {
    let fmi = fb_menu[fmi_i];
    //base schema has equal codes with/without notes so we differ them by "_with_notes" appendix
    fb_menu_map[fmi.value + (schema === 'base' && fmi.with_notes ? "_with_notes" : "")] = fmi;
  }
  if (!fb_menu_map[fb_key]) {
    console.error("get_fmi_by_key: not found", fb_menu, fb_key);
    return undefined;
  }
  return fb_menu_map[fb_key];
}
function onClickPreview(e) {
  (0, _common.pr)(e);
  buildsite.api().emit("op-fail");
}
const successUnsubmitText = exports.successUnsubmitText = [`Package was successfully unsubmitted from `, `Package was successfully resubmitted to `];
const row_fields = exports.row_fields = ["id", "subsection", "product_specified", "product_submitted", "manufacturer", "notes"];
const hasWarningPdf = pkg => {
  if (!pkg || !pkg.package_data) return;
  let sections = pkg.package_data.sections,
    hasNonPdf = false,
    hasLockedPdf = false;
  const itemChecking = item => {
    if (!hasNonPdf && item.mime_type !== "application/pdf") {
      hasNonPdf = true;
    }
    let f = item.flags || {};
    if (item.mime_type === "application/pdf" && (!item.is_annotatable || f.cpdf_broken || f.pdftk_broken)) hasLockedPdf = true;
    if (hasNonPdf && hasLockedPdf) return false;
  };
  _.forEach(sections, section => {
    if (hasNonPdf && hasLockedPdf) return false;
    _.forEach(section.rows, row => {
      if (hasNonPdf && hasLockedPdf) return false;
      _.forEach(row.items, itemChecking);
    });
  });

  //documents from reviewer
  if (_.get(pkg, 'package_data.returned_documents.items', []).length && (!hasNonPdf || !hasLockedPdf)) {
    _.forEach(pkg.package_data.returned_documents.items, itemChecking);
  }

  //documents from assignee
  if (_.get(pkg, 'package_data.documents.items', []).length && (!hasNonPdf || !hasLockedPdf)) {
    _.forEach(pkg.package_data.documents.items, itemChecking);
  }
  return {
    hasNonPdf,
    hasLockedPdf
  };
};
exports.hasWarningPdf = hasWarningPdf;