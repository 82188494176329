"use strict";

const numbering = {
  masterformat: {
    label: "MasterFormat",
    order: 0,
    section: ["00", " 00", " 00", ".01"],
    subsection: ["1.", "01.", "A.", "1.", "a.", "1)", "a)"]
  },
  caltrans: {
    label: "Caltrans",
    order: 1,
    section: ["01", "-1S", "A"],
    subsection: [".01", "A", "(1)", "(a)", "(r)", "(A)"]
  },
  ufgs: {
    label: "UFGS",
    order: 2,
    section: ["00", " 00", " 00", ".00", " 00"],
    subsection: ["1", ".1", ".1", ".1", ".1", ".1"]
  },
  masterformat_1995: {
    label: "Old MasterFormat - 1995 Edition",
    order: 3,
    section: ["00", " 0", " 0", " 0"],
    subsection: ["1.", "01.", "A.", "1.", "a.", "1)", "a)"]
  }
};

// for sec number in TOC items recognition, when clicked
// old mf ([0-4]\d[\s_]?\d{2}[\s_]?\d{2}([\.-]\d{2})?)
// old ufgs ([0-4]\d{2}\s){2}(\d{2})(\.\d{2}(\s\d{2})?)?
const numbering_regexes = {
  masterformat: /^\s*((?:\d{2}[\s_]*\d{2}[\s_]*\d{2})(?:[.-]\d{2})?)(?:[^\d]|$)/i,
  caltrans: /^\s*(\d{1,2}-\d{1,4}[A-Z]?)/,
  // paren level:         12                             22          3         3 2 1
  ufgs: /^\s*((?:\d{2}[\s_-]\d{2}[\s_-]\d{2})(?:[.]\d{2}(?:\s\d{2})?)?)/i,
  masterformat_1995: /^\s*((?:\d{2}[\s_]*\d[\s_]*\d[\s_]*\d))(?:[^\d]|$)/i
};
const numbering_list = Object.keys(numbering); //order for dropdown

//const ordered_numbering_list = ["caltrans","ufgs","masterformat","masterformat_1995"]; //order for auto recognition

function matchSomeFormat(title) {
  if (!title) return false;
  return numbering_list.some(fmt => title.match(numbering_regexes[fmt]));
}
function getStandardNumberings() {
  return numbering_list.map(v => {
    return {
      value: v,
      label: numbering[v].label
    };
  }).sort(function (a, b) {
    return numbering[a.value].order - numbering[b.value].order;
  });
}
function guessNumbering(ppairs) {
  let pairs = ppairs,
    numbering_winner = "";
  if (typeof pairs != "object") return "masterformat";
  const nre = numbering_regexes;
  let format_toplist = _.assign(...numbering_list.map(k => ({
    [k]: 0
  })));
  _.forEach(Object.keys(pairs), val => {
    let stop;
    _.forEach(numbering_list, fmt => {
      let v = pairs[val];
      if (nre[fmt].test(v)) {
        if (fmt == "caltrans") {
          numbering_winner = fmt;
          stop = true;
          return false; //stop search
        }
        if (fmt == "ufgs") {
          let nu = v.match(nre.ufgs),
            nm = v.match(nre.masterformat);
          if (nu && nm) {
            if (nu[1].length > nm[1].length) {
              numbering_winner = fmt; //UFGS wins
              stop = true;
              return false; // stop search
            } else format_toplist["masterformat"]++; //additional point to masterformat winning
          }
        }
        format_toplist[fmt]++;
      }
    });
    if (stop) return false;
  });
  if (!numbering_winner && !Object.values(format_toplist).every(v => v == 0)) numbering_winner = numbering_list.reduce((a, b) => format_toplist[a] > format_toplist[b] ? a : b);
  console.log("ExtractBase: numbering_winner", numbering_winner, format_toplist);
  return numbering_winner;
}
function detect_parse_section_number(section_name) {
  let std_code = null,
    section = null;
  if (section_name) {
    _.forEach(numbering_list, fmt => {
      const nre = numbering_regexes;
      if (nre[fmt].test(section_name)) {
        if (fmt == "ufgs" || fmt == "masterformat") {
          let nu = section_name.match(nre.ufgs),
            nm = section_name.match(nre.masterformat);
          if (nu && nm) {
            std_code = nu[1].length > nm[1].length ? "ufgs" : "masterformat"; //if both match but ufgs is longer then ufgs wins
            return false; // stop search
          }
        }
        std_code = fmt;
        return false;
      }
    });
  }
  if (std_code) {
    // a section numbering is defined
    section = section_name.match(numbering_regexes[std_code]);
    section = section ? section[1] : null;
  }
  return [section, std_code];
}
function section_number(section) {
  if (!section) return "";
  return section.section_number || section.section_name && (detect_parse_section_number(section.section_name)[0] || section.section_name.substr(0, 8)) || "";
}
function section_name(section) {
  return section.section_name || (section.section_number ? section.section_number + (section.section_title ? " " + section.section_title : "") : "");
}
function logitem_section_number(section) {
  return section.section_number || detect_parse_section_number(section.section)[0] || section.section && section.section.substr(0, 8) || "";
}
function logitem_section_name(section) {
  return section.section || (section.section_number ? section.section_number + (section.section_title ? " " + section.section_title : "") : "");
}
module.exports = {
  numbering,
  numbering_regexes,
  numbering_list,
  matchSomeFormat,
  getStandardNumberings,
  guessNumbering,
  detect_parse_section_number,
  section_number,
  section_name,
  logitem_section_number,
  logitem_section_name
};