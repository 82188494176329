"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publicConstants = void 0;
const publicConstants = exports.publicConstants = {
  googleMapLink: "https://www.google.com/maps/place/1221+Preservation+Park+Way+%23200,+Oakland,+CA+94612,+USA/@37.8056306,-122.2774601,17z/data=!3m1!4b1!4m5!3m4!1s0x808f80bab02ee283:0xbd7d00482e9fdec!8m2!3d37.8056306!4d-122.2774601?entry=ttu",
  place: "Preservation Park",
  address: "1221 Preservation Park Way, Suite 200",
  place: "Oakland, California at Preservation Park",
  city: "Oakland, California 94612",
  phone: "888 717-8665",
  phone2: "510 208 4425",
  dateTermsUpd: "July 14, 2024",
  dateTermsUpdFull: "July 14, 2024",
  datePrivacyPolicyFull: "July 14, 2024"
};